var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.goodsId
    ? _c("div", { staticClass: "activityDetail" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "activity-content" },
          [
            _c(
              "el-table",
              { staticStyle: { width: "100%" }, attrs: { data: _vm.dataList } },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "时间"
                    }
                  },
                  [void 0],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "nickName",
                    "header-align": "center",
                    align: "center",
                    label: "类型"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createUserName",
                    "header-align": "center",
                    align: "center",
                    label: "对应活动"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "phone",
                    "header-align": "center",
                    align: "center",
                    label: "数量变化"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    "header-align": "center",
                    align: "center",
                    label: "可用数量"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    "header-align": "center",
                    align: "center",
                    label: "冻结数量"
                  }
                })
              ],
              1
            ),
            _c("el-pagination", {
              staticClass: "page",
              attrs: {
                "current-page": _vm.pageIndex,
                "page-sizes": [10, 20, 50, 100],
                "page-size": _vm.pageSize,
                total: _vm.totalPage,
                layout: "total, sizes, prev, pager, next, jumper"
              },
              on: {
                "size-change": _vm.sizeChangeHandle,
                "current-change": _vm.currentChangeHandle
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity-title" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v("已购奖品明细 "),
        _c("span", [_vm._v(" -- 1元话费")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }