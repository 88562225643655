<template>
  <!-- 活动明细 -->
  <div class="activityDetail" v-if="goodsId">
    <div class="activity-title">
      <h3 class="title">已购奖品明细 <span> -- 1元话费</span></h3>
    </div>
    <div class="activity-content">
      <el-table
        :data="dataList"
        style="width: 100%;">
        <el-table-column
          header-align="center"
          align="center"
          label="时间">
          <template>
          </template>
        </el-table-column>
        <el-table-column
          prop="nickName"
          header-align="center"
          align="center"
          label="类型">
        </el-table-column>
        <el-table-column
          prop="createUserName"
          header-align="center"
          align="center"
          label="对应活动">
        </el-table-column>
        <el-table-column
          prop="phone"
          header-align="center"
          align="center"
          label="数量变化">
        </el-table-column>
        <el-table-column
          prop="createTime"
          header-align="center"
          align="center"
          label="可用数量">
        </el-table-column>
        <el-table-column
          prop="createTime"
          header-align="center"
          align="center"
          label="冻结数量">
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goodsId: this.$route.query.goodsId || '',
      dataList: []
    }
  },
  created () {
    console.log(this.$route)
  }
}
</script>

<style lang="scss" scoped>
  .activityDetail {
    .activity-title {
      height: 70px;
      line-height: 70px;
      padding-left: 360px;
      border-bottom: 2px solid #ccc;
      .title {
        font-weight: 400;
        span {
          font-size: 16px;
          color: #666;
        }
      }
    }
    .activity-content {
      padding: 50px 360px;
      .page {
        margin-top: 40px;
        text-align: right;
      }
    }
  }
</style>
